@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #f5f5f5 !important;
}

.slick-prev {
  left: 3% !important;
  z-index: 1;
}

.slick-next {
  left: 3% !important;
  z-index: 1;
}
